
import { defineComponent, PropType, computed } from 'vue';

import { Link } from '@/model/content';

export default defineComponent({
  props: {
    link: {
      type: Object as PropType<Link>,
      required: true,
    },
    icon: {
      type: String,
    },
  },
  setup(props) {
    const isExternal = computed(() => props.link.url.includes('http'));

    return {
      isExternal,
    };
  },
});
