import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-54b0f3d0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "paragraph" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.to ? 'router-link' : 'button'), {
    to: _ctx.to,
    class: _normalizeClass(["icon-button", { transparent: _ctx.transparent, small: _ctx.small }]),
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('click')))
  }, {
    default: _withCtx(() => [
      _createVNode(_component_Icon, {
        class: "icon-button-icon",
        name: _ctx.iconName
      }, null, 8, ["name"]),
      _createElementVNode("span", _hoisted_1, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ]),
      (_ctx.arrow)
        ? (_openBlock(), _createBlock(_component_Icon, {
            key: 0,
            class: "icon-button-arrow",
            name: "arrow"
          }))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }, 8, ["to", "class"]))
}