import { parseQueryBoolean } from '@/utils/query-parser';
import { ref } from 'vue';
import { LocationQuery } from 'vue-router';

const isEmbedded = ref<boolean>(false);
const showHighlights = ref<boolean>(true);

export function useEmbeddedState() {
  function setEmbeddedState(query: LocationQuery) {
    if (query.embed === undefined) return;

    isEmbedded.value = parseQueryBoolean(query.embed);
    // Only hide highlights when is set to false, otherwise always show
    showHighlights.value =
      !query.highlights || query.highlights !== 'false' ? true : parseQueryBoolean(query.highlights);
  }

  return { setEmbeddedState, isEmbedded, showHighlights };
}
