
import { defineComponent, PropType } from 'vue';
import { RouteRecordRaw } from 'vue-router';

export default defineComponent({
  emits: ['click'],
  props: {
    iconName: {
      type: String,
      required: true,
    },
    to: {
      type: Object as PropType<RouteRecordRaw>,
    },
    arrow: {
      type: Boolean,
    },
    transparent: {
      type: Boolean,
    },
    small: {
      type: Boolean,
    },
  },
});
