import { ref } from 'vue';
import { createRouter, createWebHistory, RouteLocationRaw, RouteRecordRaw } from 'vue-router';

import Layout from '@/views/Layouts/Layout.vue';
import NotFoundPage from '@/views/NotFoundPage.vue';
import { i18n, Locale } from '@/i18n';
import { useRouteHistory } from '@/composables/use-route-history';
import { useEmbeddedState } from '@/composables/use-embedded-state';

export enum Route {
  Map = 'map',
  Expedition = 'expedition',
  Content = 'content',
  NotSupported = 'notSupported',
  NotFound = 'notFound',
  LogbookOverview = 'logbookOverview',
  LogbookEditor = 'logbookCanvas',
  Logbook = 'logbook',
  Collection = 'collection',
}

export type RoutePaths = { [key in Locale]: { [key in Route]: string } };

// We configure our route model for each locale in sync with the page tree in the CMS
export const routePaths: RoutePaths = {
  nl: {
    [Route.Map]: 'kaart',
    [Route.Expedition]: 'expeditie/:slug',
    [Route.NotSupported]: 'niet-ondersteund',
    [Route.NotFound]: 'niet-gevonden',
    [Route.Content]: ':slug',
    [Route.LogbookOverview]: 'logboek',
    [Route.LogbookEditor]: 'logboek/concept',
    [Route.Logbook]: 'logboek/p/:slug',
    [Route.Collection]: 'verzameling',
  },
};

const localePath = `/:locale(${Object.values(Locale).join('|')})?`;

export function getRoute(route: Route, params?: { [key: string]: string }): RouteLocationRaw {
  const locale = i18n.global.locale.value as Locale;
  return { name: getRouteName(route, locale), params: { locale, ...params } };
}

export function getRoutePath(route: Route) {
  return `${localePath}/${routePaths[i18n.global.locale.value as Locale][route]}`;
}

export function getRouteName(route: Route, locale?: Locale) {
  const currentLocale = locale || i18n.global.locale.value;
  return `${currentLocale}${route}`;
}

// Setup multi-language route tree
const routes: Array<RouteRecordRaw> = [
  {
    path: localePath,
    component: Layout,
    children: [
      ...Object.values(Locale).flatMap(locale => {
        return [
          {
            path: routePaths[locale][Route.Map],
            name: getRouteName(Route.Map, locale),
            component: () => import(/* webpackChunkName: "map-page"*/ '@/views/MapPage.vue'),
          },
          {
            path: routePaths[locale][Route.Expedition],
            name: getRouteName(Route.Expedition, locale),
            component: () => import(/* webpackChunkName: "expedition-page"*/ '@/views/ExpeditionPage.vue'),
          },
          {
            path: routePaths[locale][Route.Content],
            name: getRouteName(Route.Content, locale),
            component: () => import(/* webpackChunkName: "content-page"*/ '@/views/ContentPage.vue'),
          },
          {
            path: routePaths[locale][Route.LogbookOverview],
            name: getRouteName(Route.LogbookOverview, locale),
            component: () => import(/* webpackChunkName: "logbook-overview-page"*/ '@/views/LogbookOverviewPage.vue'),
          },
          {
            path: routePaths[locale][Route.LogbookEditor],
            name: getRouteName(Route.LogbookEditor, locale),
            component: () => import(/* webpackChunkName: "logbook-editor-page"*/ '@/views/LogbookEditorPage.vue'),
          },
          {
            path: routePaths[locale][Route.Logbook],
            name: getRouteName(Route.Logbook, locale),
            component: () => import(/* webpackChunkName: "logbook-page"*/ '@/views/LogbookPage.vue'),
          },
          {
            path: routePaths[locale][Route.Collection],
            name: getRouteName(Route.Collection, locale),
            component: () => import(/* webpackChunkName: "collection-page"*/ '@/views/CollectionPage.vue'),
          },
          {
            path: routePaths[locale][Route.NotFound],
            name: getRouteName(Route.NotFound, locale),
            component: NotFoundPage,
          },
        ];
      }),
      // Any unmatched route will show the 404 page
      {
        path: ':pathMatch(.*)*',
        component: NotFoundPage,
      },
    ],
  },
];

export const leaveTransitionPromise = ref<Promise<void>>();
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  async scrollBehavior(to, from, savedPosition) {
    // Make sure to wait for any ongoing leave page transition before setting the scroll position of the new page
    // to avoid setting position to early.
    if (leaveTransitionPromise.value) {
      await leaveTransitionPromise.value;
    }

    // Return the saved position if present, which will be in case of browser back or previously visited pages.
    // Otherwise always set the scroll position to the top of the page for new pages.
    return savedPosition || { top: 0 };
  },
  routes,
});

const { setRouteHistory } = useRouteHistory();
const { setEmbeddedState } = useEmbeddedState();

// Runs before each route change
router.beforeEach((to, from, next) => {
  setRouteHistory(to, from);
  setEmbeddedState(to.query);

  // Check if the target route has a locale and if it differs from the current locale in i18n, update it.
  if (to.params.locale && typeof to.params.locale === 'string') {
    i18n.global.locale.value = to.params.locale;
    next();
  } else {
    // In case the route does not contain a locale, prepend the current configured i18n locale.
    next({ path: `/${i18n.global.locale.value}${to.path}` });
  }
});

export { router };
