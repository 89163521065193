
import { defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';

import IconButton from '@/components/common/IconButton.vue';
import { useBranding } from '@/composables/use-branding';
import { getRouteName, Route } from '@/router';

export default defineComponent({
  components: {
    IconButton,
  },
  setup() {
    const { brandingInfo } = useBranding();
    const { t } = useI18n();
    const mapRouteName = getRouteName(Route.Map);

    return {
      brandingInfo,
      t,
      mapRouteName,
    };
  },
});
