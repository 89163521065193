import FontFaceObserver from 'fontfaceobserver';
import { CustomFonts } from '@/model/content';

export async function loadFonts(customFonts: CustomFonts) {
  const fontPromises = [];
  if (customFonts.lightFont)
    fontPromises.push(newFont(customFonts.lightFont.url, customFonts.lightFont.fontFamily, 'light'));
  if (customFonts.regularFont)
    fontPromises.push(newFont(customFonts.regularFont.url, customFonts.regularFont.fontFamily, 'regular'));
  if (customFonts.mediumFont)
    fontPromises.push(newFont(customFonts.mediumFont.url, customFonts.mediumFont.fontFamily, 'medium'));

  const robotoObserver = new FontFaceObserver('Roboto', { weight: 400 });
  fontPromises.push(robotoObserver.load as unknown as Promise<void>);

  await Promise.all(fontPromises);
}

async function newFont(font: string, family: string, cssVariable: string) {
  const fontFace = new FontFace(family, `url(${font})`, {
    display: 'swap',
  });
  const loadedFont = await fontFace.load();
  document.fonts.add(loadedFont);
  document.documentElement.style.setProperty(`--font-title-${cssVariable}`, `${family}, Helvetica, Arial, sans-serif`);
}
