import { createI18n } from 'vue-i18n';

// Locales
import { nl } from '@/i18n/locales/nl';

// For now we only support nl as our locale, but this setup is in place to already use a resource map
// for our hardcoded strings throughout the app and make possible i18n easier to transition to.
export enum Locale {
  NL = 'nl',
}

export const DEFAULT_LOCALE = Locale.NL;

export const i18n = createI18n({
  locale: DEFAULT_LOCALE,
  fallbackLocale: DEFAULT_LOCALE,
  legacy: false,
  messages: {
    nl,
  },
});
