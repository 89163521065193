export class AppError extends Error {
  constructor(public name: string, message: string = name) {
    super(message);
    this.name = name;
  }

  public metadata = {};
}

export class HttpResponseError extends AppError {
  metadata: { statusCode: number };

  constructor(statusCode: number, name: string, message: string) {
    super(name, message);
    this.metadata = { statusCode };
  }
}

export class NotFoundError extends HttpResponseError {
  constructor(message: string) {
    super(404, 'NotFoundError', message);
  }
}
