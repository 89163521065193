export default class LazyImageLoader {
  private readonly observer: IntersectionObserver | null;
  private readonly images: string;
  private readonly appId: string;
  private readonly supportsNativeLazyLoading: boolean;
  private imageCount: number;

  constructor(appId: string) {
    this.observer = null;
    this.images = '[data-src],[data-srcset]';
    this.imageCount = 0;
    this.appId = appId;
    this.supportsNativeLazyLoading = 'loading' in HTMLImageElement.prototype;

    if (!this.supportsNativeLazyLoading) {
      this.observer = new window.IntersectionObserver(this.onEntry.bind(this), {
        rootMargin: '100px 0px',
      });
    }

    this.prepareLazyLoadForImages(document.querySelectorAll(this.images));
    this.observeDomChanges();
  }

  private prepareLazyLoadForImages(images: NodeListOf<Element>) {
    if (this.supportsNativeLazyLoading) {
      images.forEach(img => {
        LazyImageLoader.setImage(img as HTMLImageElement);
      });
    } else {
      this.observeImages(images);
    }
  }

  private observeImages(images: NodeListOf<Element>) {
    this.imageCount += images.length;

    images.forEach(image => {
      if (this.observer) {
        this.observer.observe(image);
      }
    });
  }

  private static setSrcValues(image: HTMLImageElement | HTMLSourceElement) {
    const imageSrc = image.getAttribute('data-src');
    if (imageSrc) {
      image.src = imageSrc; // eslint-disable-line no-param-reassign
    }

    const imageSrcset = image.getAttribute('data-srcset');
    if (imageSrcset) {
      image.setAttribute('srcset', imageSrcset); // image.srcset doesn't work for <source> in IE11
    }
  }

  private static setImage(image: HTMLImageElement | HTMLSourceElement) {
    LazyImageLoader.setSrcValues(image);

    image.addEventListener('load', () => {
      image.classList.add('loaded');

      if (image.parentElement) {
        image.parentElement.classList.add('image-lazyloaded');
      }
    });
  }

  private onEntry(entries: IntersectionObserverEntry[]) {
    entries.forEach(entry => {
      const image = entry.target;

      if (entry.isIntersecting) {
        this.imageCount -= 1;

        if (image instanceof HTMLSourceElement || image instanceof HTMLImageElement) {
          LazyImageLoader.setImage(image);
        }

        if (this.observer) {
          this.observer.unobserve(image);
        }
      }
    });

    if (this.imageCount === 0) {
      if (this.observer) {
        this.observer.disconnect();
      }
    }
  }

  private observeDomChanges() {
    const callback = (mutationsList: MutationRecord[]) => {
      mutationsList.forEach(mutation => {
        // only added nodes are wanted, not changes attributes or removed nodes
        if (mutation.type !== 'childList' || !mutation.addedNodes.length) {
          return;
        }

        mutation.addedNodes.forEach(node => {
          // skip: non element nodes, like comments (also: v-if <!----->), text etc.
          if (node.nodeType === Node.ELEMENT_NODE) {
            const images = (node as HTMLElement).querySelectorAll(this.images);

            // added nodes contains images which need to be lazy loaded
            if (images.length) {
              this.prepareLazyLoadForImages(images);
            }
          }
        });
      });
    };

    const mutationObserver = new MutationObserver(callback);
    const siteEl = document.getElementById(this.appId);

    if (siteEl) {
      mutationObserver.observe(siteEl, {
        attributes: false,
        childList: true,
        subtree: true,
      });
    }
  }
}
