import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a965aaca"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.isExternal ? 'a' : 'router-link'), _mergeProps({
    class: "link",
    to: _ctx.link.url,
    href: _ctx.link.url,
    target: _ctx.link.targetIsBlank ? '_blank' : '',
    rel: _ctx.link.noFollow ? 'nofollow' : ''
  }, _ctx.$attrs), {
    default: _withCtx(() => [
      (_ctx.icon)
        ? (_openBlock(), _createBlock(_component_Icon, {
            key: 0,
            name: _ctx.icon
          }, null, 8, ["name"]))
        : _createCommentVNode("", true),
      (_ctx.$slots.default)
        ? _renderSlot(_ctx.$slots, "default", { key: 1 }, undefined, true)
        : (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.link.text), 1))
    ]),
    _: 3
  }, 16, ["to", "href", "target", "rel"]))
}