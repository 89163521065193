import { createApp } from 'vue';
import { i18n } from '@/i18n';
import FloatingFocus from '@q42/floating-focus-a11y';

__webpack_public_path__ = '/statics/generated/';

import '@/style/site.scss';

import App from '@/App.vue';
import { router } from '@/router';
import { loadPolyfills } from '@/utils/polyfills';
import { clickOutside } from '@/directives/click-outside';
import ViewHeightHelper from '@/utils/view-height-helper';

const app = createApp(App);

app.use(router);
app.use(i18n);

new FloatingFocus();
new ViewHeightHelper();

// Global components
import Icon from '@/components/common/Icon.vue';
import Columns from '@/components/common/Columns.vue';
import Link from '@/components/common/Link.vue';

app.component('Icon', Icon);
app.component('Columns', Columns);
app.component('Link', Link);

// Directives
app.directive('click-outside', clickOutside);

app.mount('#app');

loadPolyfills();
