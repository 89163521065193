import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';

import { AppError, HttpResponseError, NotFoundError } from '@/services/errors';
import { i18n } from '@/i18n';
import { router } from '@/router';
import logger from '@/utils/logger';

export const BASE_URL = '/api';

export type CmsRequestOptions = AxiosRequestConfig & { prefix?: boolean };

export const request = async <T>(url: string, options: AxiosRequestConfig): Promise<AxiosResponse<T>> => {
  const { params, data, method, headers } = options;
  try {
    return await axios({
      method,
      url,
      params,
      data,
      headers,
    });
  } catch (e) {
    if ((e as AxiosError).isAxiosError) {
      const error = e as AxiosError;
      const response = error.response;
      if (response?.status) {
        logger.error(`Http error status ${response.status}`, e);
        throw mapResponseToError(response);
      } else {
        // No response returned, so there was a connection issue.
        throw new AppError('ConnectionError');
      }
    } else {
      throw e;
    }
  }
};

/**
 * Handles CMS fetch request with current locale and optional preview token
 * @param {string} path - relative url to specific api endpoint resource
 * @param {AxiosRequestConfig} options - axios request configuration options
 * @returns {Promise<AxiosResponse<T>>} - promise with axios response containing fetched data
 */
export const cmsRequest = async <T>(
  path: string,
  { prefix = true, ...options }: CmsRequestOptions,
): Promise<AxiosResponse<T>> => {
  const locale = i18n.global.locale.value;
  const url = prefix ? `${BASE_URL}/${locale}${path}` : path;

  return request<T>(mapPreviewUrl(url), options);
};

/**
 * Checks the current route for a preview token and if it exists returns the given url with this token as a query parameter
 * @param {string} url - relative url to specific api endpoint resource
 * @returns {string} - url with a possible preview token query parameter
 */
const mapPreviewUrl = (url: string) => {
  const newUrl = new URL(url, window.location.origin);
  const { searchParams } = newUrl;
  const { preview } = router.currentRoute.value.query;

  if (typeof preview === 'string') {
    searchParams.set('preview', preview);
  }

  return newUrl.toString();
};

const mapResponseToError = (response: AxiosResponse): HttpResponseError => {
  switch (response.status) {
    // Add specific errors for status codes as we go
    case 404: {
      return new NotFoundError(`Not found`);
    }
    default: {
      return new HttpResponseError(
        response.status,
        'HttpResponseError',
        `Unknown http error, code: ${response.status}`,
      );
    }
  }
};
