import _debounce from 'lodash/debounce';

class ViewHeightHelper {
  windowWidth = 0;

  constructor() {
    window.addEventListener(
      'resize',
      _debounce(() => this.setViewportCssVariable(), 300),
    );

    this.setViewportCssVariable();
  }

  destroy() {
    window.removeEventListener(
      'resize',
      _debounce(() => this.setViewportCssVariable(), 300),
    );
  }

  setViewportCssVariable() {
    const vh = window.innerHeight / 100;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }
}

export default ViewHeightHelper;
