
import { defineComponent, onMounted } from 'vue';

import LazyImageLoader from '@/utils/lazy-image-loader';

export default defineComponent({
  setup() {
    onMounted(async () => {
      new LazyImageLoader('app');
    });
  },
});
