import { onActivated, onDeactivated, watch, WatchSource, WatchStopHandle } from 'vue';
import { WatchCallback, WatchOptions } from '@vue/runtime-core';

// Within keep-alive watchers are still active, this is unwanted behavior.
export function watchWhileActive<T, Immediate extends Readonly<boolean> = false>(
  source: WatchSource<T>,
  cb: WatchCallback<T, Immediate extends true ? T | undefined : T>,
  options?: WatchOptions<Immediate>,
) {
  let watcher: WatchStopHandle | null = watch(source, cb, options);

  onActivated(() => {
    if (!watcher) {
      watcher = watch(source, cb, options);
    }
  });

  onDeactivated(() => {
    watcher?.();
    watcher = null;
  });
}
