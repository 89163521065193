import { LocaleMessages } from '@/i18n/locale';

export const nl: LocaleMessages = {
  app: {
    infoPopup: {
      openLabel: 'Open extra informatie popup',
    },
    genericPopup: {
      openLabel: 'Open popup',
      closeLabel: 'Sluit popup',
    },
    genericPanel: {
      closeLabel: 'Sluit venster',
    },
    promptPopup: {
      confirmLabel: 'Ik snap het!',
    },
    audioFallback: {
      noSupport: 'Je browser ondersteunt geen HTML5 audio.',
      linkText: 'Klik hier om het geluid te downloaden.',
      typeNotSupported: 'Je browser ondersteunt dit type audio niet.',
    },
    video: {
      startVideoLabel: 'Video afspelen',
    },
    noMobile: {
      noSupport: 'Sorry! Deze ervaring is alleen beschikbaar op grotere apparaten.',
      buttonText: 'Ik wil meer weten over dit project',
    },
    copyright: {
      showLabel: 'Toon copyright melding',
    },
    image: {
      openFullscreenLabel: 'Bekijk afbeelding op volledig scherm',
      closeFullscreenLabel: 'Volledig scherm afsluiten',
      nextLabel: 'Volgende afbeelding',
      previousLabel: 'Vorige afbeelding',
    },
  },
  navigation: {
    openMenu: 'Open menu',
    closeMenu: 'Sluit menu',
    map: 'Kaart',
    challenges: 'Challenges',
    expeditions: 'Expedities',
    logbook: 'Logboek',
    collection: 'Mijn verzameling',
    mapLogbookLink: 'Verder met je logboekpagina',
    logbookOverviewLink: 'Het expeditie logboek',
    logbookEditorLink: 'Logboekpagina maken',
  },
  map: {
    popupTitle: 'Popup',
    objects: 'object | objecten',
    timeline: 'van 550 miljoen jaar geleden tot nu',
    resetLocation: 'Reset zoomniveau',
    menu: {
      panelTitle: 'Start je onderzoek',
      expeditions: 'Expedities',
      challenges: 'Challenges',
      search: {
        placeholder: 'mammoet',
        quickLinks: 'Quick Links',
        collectionSuggestions: 'Collecties',
        occurrenceSuggestions: 'Objecten',
        noSuggestionsFound: 'Dat object hebben we nog niet gevonden, maar misschien vind je deze objecten interessant',
        clearSearchLabel: 'Zoekterm leegmaken',
        openSearchLabel: 'Open zoekmenu',
      },
    },
  },
  intro: {
    openMapButton: 'Bekijk onze vondsten op de kaart',
  },
  challenges: {
    completedChallengesListLabel: 'Behaalde challenge',
  },
  expedition: {
    startYourExpeditionButton: 'Begin jouw expeditie',
    chapterExpanderOpenLabel: 'Open expeditie navigatie',
    chapterExpanderCloseLabel: 'Sluit expeditie navigatie',
    currentChapterLabel: 'Huidig hoofdstuk',
    chapterLabel: 'Hoofdstuk',
    totalChapterLabel: 'van de',
    openMap: 'Bekijk kaart',
    backToMap: 'Terug naar de expeditie',
    quizTitle: 'Quiz',
    quizExplanation: 'Klik op een van de kaarten om te kijken of dat het juiste antwoord is',
    quizCorrect: 'Goedzo!',
    quizWrong: 'Jammer!',
    quizButtonLabel: 'Kies voor antwoord',
    openHotspotLabel: 'Open punt',
    map: {
      title: 'Tijd om zelf aan de slag te gaan! Vind op de kaart alle objecten van deze expeditie.',
      showButtonLabel: 'Ga op onderzoek',
      hideButtonLabel: 'Terug naar expeditie',
    },
  },
  objectDetail: {
    detailPanelCloseLabel: 'Sluit object detail paneel',
    loadingLabel: 'Object detail paneel is aan het laden',
    missingImage: 'Bevat geen foto of video',
    imageAlt: 'Object afbeelding',
    highlightBlockTitlePrefix: 'Onderdeel van',
    enrichmentLinksTitle: 'Lees meer over ',
    errorMessageNotFound: 'Jij zoekt het tot op het bot uit. Daar houden we van! Maar hier is (nog) niets gevonden.',
    errorMessageDefault: 'Er ging iets mis. Probeer het later nog eens',
    errorBackButton: 'Terug naar de kaart',
    wikidata: {
      labelAnimalSound: 'Hoe klinkt dit dier?',
      buttonTextTaxonRangeMap: 'Natuurlijk leefgebied',
      buttonTextSizeComparison: 'Grootte',
    },
    inaccurateLocation: 'Dit object is in de omgeving gevonden, maar we zijn niet zeker van de exacte locatie',
    collectButtonLabel: 'Verzamelen',
  },
  notFound: {
    titleFirstPart: 'Verdwaald in',
    titleSecondPart: 'het wild',
    text: 'Deze pagina bestaat niet. De pagina die je zocht hebben we niet kunnen vinden. Ga verder naar de kaart.',
    linkText: 'Terug naar de kaart',
    foregroundImageAlt: 'Walrus',
  },
  logbook: {
    editorEmptyState: {
      buttonLabel: 'Aan de slag',
    },
    objectControls: {
      deleteLabel: 'Verwijder',
      bringToFrontLabel: 'Breng naar voren',
      sendToBackLabel: 'Stuur naar achteren',
      colorBlackLabel: 'Verander kleur naar zwart',
      colorWhiteLabel: 'Verander kleur naar wit',
      colorRedLabel: 'Verander kleur naar rood',
    },
    shapeEditor: {
      menuLabel: 'Open standaard vormen',
      rectangleLabel: 'Voeg rechthoek toe',
      ellipseLabel: 'Voeg cirkel toe',
      lineLabel: 'Voeg lijn toe',
      triangleLabel: 'Voeg driehoek toe',
    },
    textEditor: {
      defaultText: 'Tekst',
      buttonLabel: 'Voeg tekst toe',
    },
    collectionEditor: {
      collectionMenuTitle: 'Mijn verzameling',
      collectionMenuObjects: 'objecten',
      menuLabel: 'Open jouw verzameling',
      addButtonLabel: 'Voeg afbeelding toe aan logboek',
    },
    publish: {
      modalTitle: 'Helemaal klaar?',
      publishButtonText: 'Pagina publiceren',
      titleInputLabel: 'Geef je pagina een titel',
      validationErrorText: 'Vergeet niet de naam van je pagina.',
      errorText: 'Er is iets misgegaan met het publiceren van je pagina. Probeer het later opnieuw.',
      previewImageLabel: 'Logboek pagina voorbeeld afbeelding',
    },
    page: {
      onboardingGoToMap: 'Start met het verzamelen van objecten en maak je eigen pagina in Het Logboek',
      showMoreLabelImages: 'toon meer',
      showLessLabelImages: 'toon minder',
      loadMoreItemsLabel: 'Laad meer',
      titlePartOne: 'Het Expeditie',
      logbook: 'Logboek',
      deleteTitle: 'Weet je het zeker?',
      deleteText: 'Op deze manier verwijder je het object uit je verzameling.',
      cancelDelete: 'Nee, object laten staan',
      confirmDelete: 'Ja, object verwijderen',
      share: {
        nativePopup: 'Deel dit logboek',
        expand: 'Open logboek delen menu',
        collapse: 'Sluit logboek delen menu',
        facebook: 'Deel logboek op Facebook',
        twitter: 'Deel logboek op twitter',
        pinterest: 'Deel logboek op Pinterest',
      },
    },
    view: {
      copyrightIntro: 'Dit werk is een remix van de onderstaande afbeeldingen',
      copyrightDerivedExplanation: 'De afbeelding is een afgeleide van',
    },
    publishedLogbookCard: {
      page: 'Pagina',
      curatedText: 'Samengesteld door',
    },
  },
};
