import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["xlink:href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", {
    class: _normalizeClass('icon-' + _ctx.name)
  }, [
    _createElementVNode("use", {
      "xlink:href": '#icon-' + _ctx.name
    }, null, 8, _hoisted_1)
  ], 2))
}