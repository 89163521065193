import { ref } from 'vue';

import { Branding } from '@/model/content';
import { getBranding } from '@/services/branding-service';
import { loadFonts } from '@/utils/branding';

const brandingInfo = ref<Branding>();
export function useBranding() {
  async function applyBranding() {
    brandingInfo.value = await getBranding();
    document.documentElement.style.setProperty('--primary-color', `#${brandingInfo.value.primaryColor}`);
    if (brandingInfo.value.secondaryColor)
      document.documentElement.style.setProperty('--secondary-color', `#${brandingInfo.value.secondaryColor}`);
    if (brandingInfo.value.customFonts) await loadFonts(brandingInfo.value.customFonts);
  }

  return {
    applyBranding,
    brandingInfo,
  };
}
