<template>
  <div class="columns">
    <slot></slot>
  </div>
</template>

<style lang="scss">
.columns {
  display: flex;
  margin: 0 -15px;

  & > * {
    flex: 1;
    margin: 0 15px;
  }

  @media #{$mobile} {
    flex-direction: column;
  }
}
</style>
