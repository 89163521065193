import { ref } from 'vue';

export function useLoader(initialLoading = true) {
  const loading = ref(initialLoading || false);

  function startLoading() {
    loading.value = true;
  }

  function stopLoading() {
    loading.value = false;
  }

  return {
    loading,
    startLoading,
    stopLoading,
  };
}
