import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6a89a9af"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "fullscreen-loader"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Loader = _resolveComponent("Loader")!

  return (_openBlock(), _createBlock(_Transition, { name: "short-fade" }, {
    default: _withCtx(() => [
      (_ctx.loading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_Transition, { name: "short-fade" }, {
              default: _withCtx(() => [
                _createElementVNode("div", null, [
                  (_ctx.debouncedLoading)
                    ? (_openBlock(), _createBlock(_component_Loader, { key: 0 }))
                    : _createCommentVNode("", true)
                ])
              ]),
              _: 1
            })
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}