/**
 * Log to the console
 */
class Logger {
  /**
   * Log whatever you want on the info level.
   * This is only visible in the dev-mode of the app.
   */
  info(message: string, ...optionalParams: any[]) {
    // Only log info level in debug mode.
    process.env.NODE_ENV === 'development' && console.info(`[EXPEDITION] ${message}`, ...optionalParams);
  }
  /**
   * Log a warning.
   */
  warn(message: string, error?: unknown) {
    console.warn(message, error || '');
  }
  /**
   * Log an error.
   */
  error(message: string, error: unknown) {
    console.error(message, error);
  }
}

export default new Logger();
