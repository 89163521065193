import { AnimeAnimParams } from 'animejs';

export interface PageTransitionAnimeOptions {
  leave: AnimeAnimParams;
  enter: AnimeAnimParams;
}

export const DEFAULT_PAGE_TRANSITION_DURATION = 650;
export const DEFAULT_PAGE_TRANSITION_ANIME_OPTIONS = {
  duration: DEFAULT_PAGE_TRANSITION_DURATION,
  easing: 'easeInOutQuint',
};

/**
 * Get curtain page transition anime options for specific route from and to combinations
 * @returns {PageTransitionAnimeOptions|undefined} - The matching page transition anime options or undefined if no transition is necessary for the from and to routes
 */
export function getCurtainPageTransitionOptions(): PageTransitionAnimeOptions {
  return {
    leave: {
      duration: 250,
      opacity: [0, 1],
      easing: 'easeInQuad',
    },
    enter: {
      duration: 250,
      opacity: [1, 0],
      easing: 'easeOutQuad',
    },
  };
}
