
import { defineComponent, onMounted, ref } from 'vue';
import _debounce from 'lodash/debounce';

import Loader from '@/components/common/Loader.vue';
import { watchWhileActive } from '@/utils/vue-lifecycle';

export default defineComponent({
  components: {
    Loader,
  },
  props: {
    loading: {
      type: Boolean,
    },
    debounced: {
      type: Boolean,
    },
  },
  setup(props) {
    const debouncedLoading = ref(false);

    onMounted(debouncedLoad);

    function debouncedLoad() {
      if (props.debounced) {
        _debounce(() => (debouncedLoading.value = true), 500)();
      } else {
        debouncedLoading.value = true;
      }
    }

    watchWhileActive(
      () => props.loading,
      loading => {
        if (loading) {
          debouncedLoad();
        }
      },
    );

    return {
      debouncedLoading,
    };
  },
});
