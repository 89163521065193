import { Branding } from '@/model/content';
import { cmsRequest } from '@/services/api';

/**
 * Fetches branding
 * @returns {Promise<Branding>} - Branding api response
 */

export async function getBranding(): Promise<Branding> {
  const response = await cmsRequest<Branding>(`/branding`, { method: 'get' });
  return response.data;
}
