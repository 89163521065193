import { ref } from 'vue';
import { RouteLocationNormalized } from 'vue-router';

export interface RouteHistory {
  to: RouteLocationNormalized;
  from: RouteLocationNormalized;
}

const routeHistory = ref<RouteHistory>();
export function useRouteHistory() {
  function setRouteHistory(to: RouteLocationNormalized, from: RouteLocationNormalized) {
    routeHistory.value = {
      to,
      from,
    };
  }

  return {
    routeHistory,
    setRouteHistory,
  };
}
