
import { defineComponent, nextTick, onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import anime from 'animejs';

import Loader from '@/components/common/Loader.vue';
import FullscreenLoader from '@/components/common/FullscreenLoader.vue';
import { getCurtainPageTransitionOptions } from '@/utils/page-transition';
import { useLoader } from '@/composables/use-loader';
import { useBranding } from '@/composables/use-branding';
import { leaveTransitionPromise } from '@/router';

export default defineComponent({
  components: {
    Loader,
    FullscreenLoader,
  },
  setup() {
    const pageTransitionCurtainEl = ref<HTMLDivElement>();
    const pageLoadPromise = ref<Promise<void>>();
    const transitioning = ref(false);

    const route = useRoute();
    const { applyBranding } = useBranding();
    const { loading: initialLoading, stopLoading: stopInitialLoading } = useLoader();
    const { loading: pageLoading, stopLoading: stopPageLoading } = useLoader();

    let initialized = false;

    onMounted(async () => {
      // First load initial required data, like branding before mounting page.
      await applyBranding();
      stopInitialLoading();

      // Wait for any rendered page to register possible delay promise, as page component
      // mounts after initial loading has stopped.
      await nextTick();

      // Load any page related data before removing the loader overlay.
      if (pageLoadPromise.value) {
        await pageLoadPromise.value;
        pageLoadPromise.value = undefined;
      }

      stopPageLoading();
    });

    /**
     * Enter transition hook for the new incoming page (router to)
     * @param {HTMLElement} el - Page container element
     * @param {Function} done - Transition finish callback function
     */
    async function enterTransition(el: HTMLElement, done: () => void) {
      // Prevent initial load transition
      if (!initialized) {
        initialized = true;
        done();
        return;
      }

      // If the new page requires some loading first, wait for it to be finished before transitioning out.
      // Used in for example waiting for the mapbox instance to load or for some API calls to finish fetching page data.
      if (pageLoadPromise.value) {
        await pageLoadPromise.value;
      }

      const enterTransitionPromise = createEnterTransitionPromise();
      await enterTransitionPromise;

      transitioning.value = false;
      pageLoadPromise.value = undefined;
      done();
    }

    function createEnterTransitionPromise() {
      const curtainAnimeOptions = getCurtainPageTransitionOptions();
      return new Promise(resolve => {
        anime({
          ...curtainAnimeOptions.enter,
          targets: pageTransitionCurtainEl.value,
          complete: resolve,
        });
      });
    }

    /**
     * Leave transition hook for the current active page (router from)
     * @param {HTMLElement} el - Page container element
     * @param {Function} done - Transition finish callback function
     */
    async function leaveTransition(el: HTMLElement, done: () => void) {
      // Clear any registered delay promise as we only care for delay promises in the enter hook
      pageLoadPromise.value = undefined;
      transitioning.value = true;

      // Set the leave page transition promise that will be used in the scroll behavior router hook
      // to wait before setting the scroll position of the incoming page
      leaveTransitionPromise.value = createLeaveTransitionPromise();
      await leaveTransitionPromise.value;

      // Call the done callback function to notify the transition component to start the enter transition
      done();
    }

    async function createLeaveTransitionPromise(): Promise<void> {
      await nextTick();

      return await new Promise(resolve => {
        const curtainAnimeOptions = getCurtainPageTransitionOptions();
        anime({
          ...curtainAnimeOptions.leave,
          targets: pageTransitionCurtainEl.value,
          complete: () => resolve(),
        });
      });
    }

    /**
     * Register function for page components to notify our page transition system to first wait for
     * the promise to resolve before starting the enter transition.
     * @param {Promise<void>} promise - Delay promise
     */
    function registerTransitionDelay(promise: Promise<void>) {
      pageLoadPromise.value = promise;
    }

    return {
      route,
      pageTransitionCurtainEl,
      pageLoadPromise,
      transitioning,
      initialLoading,
      pageLoading,
      enterTransition,
      leaveTransition,
      registerTransitionDelay,
    };
  },
});
